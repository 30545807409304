<template>
  <div>
    <b-container>
      <b-row class="mb-5">

        <b-col
          id="content_section"
          cols="12"
          class="mt-5 text-center"
        ><h1>With joy in diversity, we create a colorful society, with us integration is easy
         </h1>
          <b-button
            class="my-3"
            variant="primary"
            @click="$router.push({name:'candidates'})"
          >Access the talent pool
          </b-button>
        </b-col>
        <b-col
          cols="12"
          class="my-5"
        >
          <div class="demo-vertical-spacing">
            <div class="progress-wrapper">
              <b-card-text class="mb-0">
                <div
                  class="icon-background-primary"
                >
                  <fa-icon
                    pack="fas"
                    icon="graduation-cap"
                    color="#161D31"
                  />
                </div>
                Masters/Ph.D. degree holders of equivalent {{ 50 + '%' }}
              </b-card-text>
              <b-progress
                show-value
                value="50"
                max="100"
              />
            </div>
            <div class="progress-wrapper">
              <b-card-text class="mb-0">
                <div
                  class="icon-background-primary"
                >
                  <fa-icon
                    pack="fas"
                    icon="bookmark"
                    color="#161D31"
                  />
                </div>
                Bachelor's degree holders or equivalent {{ 30 + '%' }}
              </b-card-text>
              <b-progress
                show-value
                value="30"
                max="100"
              />
            </div>
            <div class="progress-wrapper">
              <b-card-text class="mb-0">
                <div
                  class="icon-background-primary"
                >
                  <fa-icon
                    pack="fas"
                    icon="key"
                    color="#161D31"
                  />
                </div>
                Secondary school cert or equivalent {{ 20 + '%' }}
              </b-card-text>
              <b-progress
                show-value
                value="20"
                max="100"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container
      fluid
      class="px-lg-5 py-5 background-opacity-primary"
    >

      <b-row class="px-lg-5 text-center py-2 justify-content-center">
        <b-col
          id="AboutUs"
          cols="12"
          lg="7"
          class="text-black text-center px-lg-5 py-2"
        >
          <h1 class="AboutHeader">
            ABOUT US
          </h1>
          <br>
          <br>
          EmployRefugees is a social enterprise that is supported by Social Entrepreneurs Ireland. This project is a winner of the 2021 empowering Refugee Entrepreneurs award. We are networked with the change makers in the refugees sector – from local organistions to authorities. We have access to a broad countrywide network of Refugee & Asylum-Seeker talent, who are keen to prove themselves in the Irish world of work. Our aim is to increase the diversity and inclusiveness of your workforce. It also could contribute profoundly to your workplace culture (with employees having unexpected and advanced skill-sets). It could potentially reinforce and reinvigorate a solid work ethic, with the addition of highly motivated Refugee and Asylum-Seeker staff. Don't forget that many refugees and asylum seekers have significant training and experience in their own home countries. Several of these countries would actually have had a higher standard of education compared to what we have here. We'll find the suitable and well-motivated candidates for your company.
          <h3 class="about_us_content mt-2">
            Employrefugees is provided free of charge to employers and to refugees
          </h3>
        </b-col>
        <b-col
          cols="12"
          class="text-black text-center"
        >
          <div>
            <video
              class="AboutVideo"
              controls
              :poster="require('@/assets/images/HT/hands.png')"
            >
              <source
                :src="require('@/assets/video/ireland_movie.mp4')"
                type="video/mp4"
              >
            </video>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="7"
          class="text-black text-center"
        >
          <div class="progressbar-container my-5">
            <div class="progressbar">
              <div class="active">
                We communicate with you
              </div>
              <div class="active c2">
                We share your thoughts
              </div>
              <div class="active c3">
                We share your success
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="7"
          class="text-black text-center"
        >
          <img
            class="about_us_image mt-3 About_image"
            :src="require('@/assets/images/HT/pepoel.jpg')"
          >
          <br>
          <br>
          <b-button
            variant="primary"
            class="mt-5"
            @click="$router.push({name:'candidates'})"
          >
            Post your job position for free and access the talent pool
          </b-button>

        </b-col>
      </b-row>

    </b-container>
    <b-container>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          class="hidden"
        >
          <h1 class="text-center py-5">
            Some of companies that we have already
          </h1>
        </b-col>
        <!-- disable animation-->
        <b-modal
          id="modal-center"
          v-model="avatarDesc"
          centered
          hide-footer
          :title="modelTitle"
        >
          <b-card-text>
            {{ modelContent }}
          </b-card-text>
        </b-modal>

        <b-col
          v-for="data in swiperData"
          :key="data.img"
          class="text-center my-1 hidden"
          cols="12"
          md="4"
          @click="openAvatarDesc(data)"
        >
          <b-img
            class="about_us_image"
            width="200px"
            :src="data.img"
            fluid
          />
        </b-col>
        <b-col
          v-for="data in arrangedInterviews"
          :key="data.img"
          cols="4"
          class="text-center hidden"
        >
          <b-img
            class="arrangedInterviews-img"
            :src="data.img"
            fluid
          />
        </b-col>

        <b-col
          cols="12"
          lg="6"
          class="py-5 hidden"
        >
          <b-card class="traning-card">
            <b-row>
              <b-col
                class="py-2 px-3"
                cols="12"
                lg="6"
              >
                <h3>Siobahn| CEO SCF Processing, Ireland</h3>
                <h5 class="py-1">
                  I am interested in the role of employment in preventing marginalisation. Your work is very important for the future of new communities in Ireland.” – Felim O Rourke (Economist).
                  Felim O Rourke | Economist, Ireland
                </h5>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <b-img
                  class="traning-image-first"
                  :src="require('@/assets/images/HT/Picture21.jpg')"
                  fluid
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          class="py-5 hidden"
        >
          <b-card class="traning-card">
            <b-row>
              <b-col
                class="py-2 px-3"
                cols="12"
                lg="6"
              >
                <h3>Niamh Phelan| CEO Holistic Analytics, Ireland</h3>
                <h5 class="py-1">
                  Employrefugees is a social enterprise that is supported by Social Entrepreneurs Ireland.
                  We are networked with the change makers in the refugee sector – from local organistions to authorities.Employ Refugees have access to a broad countrywide network of Refugee & Asylum-Seeker talent.
                </h5>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <b-img
                  class="traning-image-second"
                  :src="require('@/assets/images/HT/Picture20.jpg')"
                  fluid
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          class="py-5"
        >
          <h1 class="text-center">
            How we work
          </h1>
        </b-col>
        <b-col
          cols="12"
          class="py-5"
        >
          <b-row>
            <b-col
              v-for="card in cards"
              :key="card.title"
              cols="12"
              lg="6"
            >
              <b-card>
                <b-img
                  class="b-card-img"
                  :src="card.img"
                  fluid
                />
                <h1>{{ card.title }}</h1>
                {{ card.content }}

              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          class="py-5"
        >
          <h1 class="text-center">
            What People Say About Us:
          </h1>
        </b-col>
        <b-col
          cols="12"
          class="py-5"
        >
          <b-row>
            <b-col
              v-for="card in WhoSpeak"
              :key="card.title"
              cols="12"
              :lg="card.size"
            >
              <b-card>

                {{ card.content }}

                <h6 class="text-end py-1 text-primary">
                  {{ card.title }}
                </h6>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          class="text-center py-5"
        >
          <h1 class="text-center">
            OUR SPONSORS AND PARTNERS
          </h1>
          We gratefully acknowledge the contributions of our funders and partners who are our change-makers to refugees' lives in Ireland.
        </b-col>
        <b-col cols="12">
          <swiper
            class="swiper-multiple"
            :options="logosOptions"
          >
            <swiper-slide
              v-for="data in logos"
              :key="data.img"
            >
              <b-img
                class="swiperslider-logo-image"
                :src="data.img"
                fluid
              />
            </swiper-slide>
            <div
              slot="pagination"
              class="swiper-pagination"
            />
          </swiper>
        </b-col>
        <b-col
          cols="12"
          class="py-5"
        >
          <b-card class="traning-card">
            <b-row>
              <b-col
                class="p-5"
                cols="12"
                lg="6"
              >
                <h1>Employment Seekers</h1>
                <h5 class="py-2">
                  We facilitate and organise language courses and further training in cooperation with Centre of English Studies plus The Open Doors Initiative.
                </h5>
                <div class="text-center">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSctsJQS69VGfUv8urWRjxEmihahExuopps8SmKU5E9Jotvxxg/viewform"
                    class="mx-1"
                  >
                    <b-button
                      variant="primary"
                      class="my-1"
                    >
                      Test your english level now
                    </b-button>
                  </a>
                  <a
                    href="https://cesdirectlearning.podia.com"
                    class="mx-1"
                  >
                    <b-button
                      variant="primary"
                      class="my-1"
                    >
                      Learn English
                    </b-button>
                  </a>
                </div>

              </b-col>
              <b-col
                cols="12"
                lg="6"
                class="text-end"
              >
                <b-img
                  class="traning-image"
                  :src="require('@/assets/images/HT/redpepoel.jpg')"
                  fluid
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col
          class="mt-5"
          cols="12 text-center"
        >
          <h1>
            OUR THANKS GO TO EVERYONE WHO MADE THIS PROJECT POSSIBLE
          </h1>
        </b-col>
        <b-col
          class="mt-5 text-center align-self-center "
          cols="6"
        >
          <b-img
            class="swiperslider-logo-image"
            :src="require('@/assets/images/HT/OURTHANKS.jpg')"
            fluid
          />
        </b-col>
        <b-col
          class="mt-5"
          cols="12"
        >
          <ht-contact />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import HtContact from '@/views/home/contact.vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    HtContact, Swiper, SwiperSlide, BImg,
  },
  /* eslint-disable global-require */
  data() {
    return {
      modelContent: '',
      modelTitle: '',
      avatarDesc: false,
      cards: [
        {
          title: 'Provide the training',
          content: 'EmployRefugees provides candidates courses that suit their career prospects and help them to improve their CVs and prepare them for job interviews.',
          img: require('@/assets/images/HT/Picture8.png'),
        },
        {
          title: 'Identify role',
          content: 'After providing us with the job description, we identify the most motivated and suitable candidates with skills relevant to the job position in your company.',
          img: require('@/assets/images/HT/Picture9.png'),
        },
        { title: 'Facilitates the interview process', content: 'We provide companies a shortlist of CVs and arrange  interviews with selected candidates.', img: require('@/assets/images/HT/Picture10.png') },
        { title: 'Recruitment', content: 'In the end, after finding the successful candidate, the employer issues a job offer and a contract to sign by the selected candidate.', img: require('@/assets/images/HT/Picture11.png') },
      ],
      WhoSpeak: [
        {
          size: 6,
          title: 'Felim O Rourke| Economist, Ireland.',
          content: 'I am interested in the role of employment in preventing marginalisation. Your work is very important for the future of new communities in Ireland.',
        },
        {
          size: 6,
          title: 'Jeanne McDonagh| CEO at The Open Doors Initiative, Ireland.',
          content: 'We have worked with Employ Refugees on a number of project and they are always professional and courteous, have a great range of highly qualified candidates and are a pleasure to deal with. We would highly recommend their service to any employer.',
        },
        {
          size: 6,
          title: 'Niamh Phelan| Dublin City of Sancturay Business & Enterprise program and CEO at Holistic Company , Ireland.',
          content: 'We at Dublin City of Sanctuary Business & Enterprise programme are in awe of the amazing work done by the team at EmployRefugees.com. Ghada has identified an opportunity to assist refugees into employment and has filled that gap with helpful resources and guidance to simplify their job searches. Best of luck to the team as the project develops.\n'
              + 'Niamh Phelan | Dublin City of Sancturay Business & Enterprise program and CEO at Holistic Company , Ireland.',
        }, {
          size: 6,
          title: 'Maria Acuna Gonzalez | Project Coordinator at Development Perspectives, Ireland.',
          content: 'EmployRefugees provides a platform for connecting refugees looking for opportunities and employers.\n\n It presents a great opportunity to establish connections that can benefit both parts.\n This are the kind of platforms that have been needed for a long time.\n',
        },
      ],
      value1: 70,
      setLoaded: false,
      arrangedInterviews: [
        { img: require('@/assets/images/HT/Picture5.png') },
        { img: require('@/assets/images/HT/Picture6.png') },
        { img: require('@/assets/images/HT/Picture7.png') },
      ],
      swiperData: [
        {
          img: require('@/assets/images/HT/Picture1.jpg'),
          title: 'Suhair',
          content: 'Suhair is a holder of a masters in geography and also a masters of psychology with not much\n'
              + 'English. she lives in Ireland since three years and she said \'being without work in Ireland is like a\n'
              + 'prison and I am happy to start my career as an operative worker. The job gives me freedom to go\n'
              + 'out and gives a significance to my life\'.\n'
              + 'Get in touch with us with more inspiring stories about\n'
              + 'how lives have changed for refugees who found their\n'
              + 'careers in Ireland.\n',
        },
        {
          img: require('@/assets/images/HT/Picture2.jpg'),
          title: 'Hassan',
          content: 'Hassan is a holder of an English literature diploma from Syria, He said \'I am happy to start my first\n'
              + 'job in Ireland as I have applied for family reunification to my wife to join me here and I am happy\n'
              + 'as my life in Ireland starts to be estabished\'.\n',
        },
        {
          img: require('@/assets/images/HT/Picture3.jpg'),
          title: 'Tarek',
          content: 'Tarek is a refugee from Syria, Aleppo, He esacped the Syrian war to Lebanon in due time he was\n'
              + 'working as a mechanic. He got in touch with employrefugees platform to look for a job he states \'As a young man, it is not easy to stay in home as I feel I have the energy and I want to integrate into the Irish society with a job that I can be active and to feel that I am one of this big society\'\n'
              + 'Home is everything and I feel Ireland is my home but having a job is like building your confidence and personality.\n',
        },
      ],
      logos: [
        // { img: require('@/assets/images/HT/Picture12.png') },
        // { img: require('@/assets/images/HT/Picture13.png') },
        // { img: require('@/assets/images/HT/Picture14.png') },
        // { img: require('@/assets/images/HT/Picture15.png') },
        // { img: require('@/assets/images/HT/Picture16.png') },
        // { img: require('@/assets/images/HT/Picture17.jpg') },
        // { img: require('@/assets/images/HT/Picture18.png') },
        // { img: require('@/assets/images/HT/Picture19.png') },
        { img: require('@/assets/images/HT/LogoPicture1.jpg') },
        { img: require('@/assets/images/HT/LogoPicture2.jpg') },
        { img: require('@/assets/images/HT/LogoPicture3.jpg') },
        { img: require('@/assets/images/HT/LogoPicture4.jpg') },
        { img: require('@/assets/images/HT/LogoPicture5.jpg') },
        { img: require('@/assets/images/HT/LogoPicture6.jpg') },
        { img: require('@/assets/images/HT/LogoPicture7.jpg') },
        { img: require('@/assets/images/HT/LogoPicture8.jpg') },
        { img: require('@/assets/images/HT/LogoPicture9.jpg') },
      ],
      logosOptions: {
        autoplay: {
          delay: 2000,
          waitForTransition: false,
          disableOnInteraction: false,
        },
        slidesPerView: 4,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 3,
        spaceBetween: 0,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
  methods: {
    openAvatarDesc(el) {
      this.avatarDesc = true
      this.modelContent = el.content
      this.modelTitle = el.title
    },
  },
}
</script>
<style scoped>
.AboutVideo{
  width: 40%;
}
@media only screen and (max-width: 900px) {
  .AboutVideo{
    width: 100%;
  }
}
.About_image{
  width: 40%;
}
@media only screen and (max-width: 900px) {
  .About_image{
    width: 80%;
  }
}
</style>
