<template>
  <div>
    <b-container>
      <b-row
        align-v="center"
        class=" mt-5 justify-content-md-center"
      >
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col
              cols="12"
              class="text-center mb-5"
            >
              <h5><b>
                <!--                Are you interested in joining us as a changemaker? A volunteer? A supporter for refugees in Ireland? Our aim is to encourage refugees to work professionally and to stand on their own two feet. Contact us!-->
                If you are an employer? a changemaker? a volunteer? a supporter for refugees in Ireland? Join us today in our mission to help refugees access the labour market, lower the cultural barriers, and rebuild their lives with dignity!

                Our aim is to encourage refugees to work professionally and to stand on their own two feet.
              </b></h5>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <vx-input
                v-model="form.name"
                :label="$t('Name')"
              />
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <vx-input
                v-model="form.email"
                :label="$t('Email')"
              />
            </b-col>
            <b-col
              cols="12"
            >
              <vx-input
                v-model="form.subject"
                :label="$t('Subject')"
              />
            </b-col>
            <b-col
              cols="12"
            >
              <vs-textarea
                v-model="form.message"
                :label="$t('Message')"
              />
            </b-col>
            <b-col
              cols="12"
            >
              <b-button
                variant="gradient-primary"
                @click="contactUs"
              >
                {{ $t('Send') }}
              </b-button>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

export default {
  name: 'HtContact',
  data() {
    return {
      form: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
    }
  },
  methods: {
    contactUs() {
      this.$store.dispatch('home/contactUs', this.form).then(() => {
        this.form = {
          name: '',
          email: '',
          subject: '',
          message: '',
        }
      })
    },
  },
}
</script>
